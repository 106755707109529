import type { UseFetchOptions } from 'nuxt/app'

export const useApi = () => {

  const getWithToken = <T>(url: string, token: string, options?: any) => {
    return useNuxtApp().$api<T>(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  const get = <T>(url: string, options?: any) => {
    return useNuxtApp().$api<T>(url, options);
  }

 const post = <T>(url: string, data?: any, options?: any) => {
    return useNuxtApp().$api<T>(url, {
      ...options,
      method: 'post',
      body: data
    });
 }

const put = <T>(url: string, data: any, options?: any) => {
    return useNuxtApp().$api<T>(url, {
      ...options,
      method: 'put',
      body: data
    });
}

  const del = <T>(url: string, options?: any) => {
    return useNuxtApp().$api<T>(url, {
      ...options,
      method: 'delete'
    });
  }

  const fetch = <T>(url: string | (() => string),
                       options?: UseFetchOptions<T>) => {
    return useFetch(url, {
      ...options,
      $fetch: useNuxtApp().$api
    })
  }

  return {
    get,
    getWithToken,
    post,
    put,
    del,
    fetch
  }

}
